import { toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css'
toast.configure()

const configSuccess = {
    position: "bottom-right",
    style: {
        backgroundColor: "#41588f",
        fontSize: '20px'
    }
}

const configError = {
    position: "bottom-right",
    style: {
        backgroundColor: "#343232",
        fontSize: '20px'
    }
}

export default {
    success: message => toast.success(message, configSuccess),
    error: message => toast.error(message, configError)
}