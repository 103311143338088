const CONSTANTS = {
  BACKEND_URL: 'https://oechsler.wesolvvo.com/api',
  FRONTEND_URL: 'https://oechsler.wesolvvo.com',
  DATE_FORMAT: 'YYYY/MM/DD hh:mm:s',
  CODES: {
    ACCESS: 202,
    FORBIDDEN: 403
  },
  PARSE_INT_RADIX: 10,
  ROWS_PER_PAGE_ARRAY: [50, 100, 150],
  INITIAL_ROWS_PER_PAGE: 50,
  DEPARTMENT: ['Teren & Cladire', 'Parc Auto', 'Intretinere general', 'Mentenanta Matrite Injectie', 'Management de cumpărături', 'Pregatirea Materiei Prime', 'Departament Injectare ', 'Departament Stampare', 'Inginerie Aplicata Matrite', 'Departament Montaj ', 'Mentenanta Automatizare', 'Management Productie', 'Planificare Productie', 'Siguranta si securitatea in munca', 'Management de proiect', 'Management Calitate', 'Controlul materiei prime - metal', 'Controlul materiei prime - granulat', 'Măsurare și de laborator', 'Control Calitate', 'Planificare Calitate', 'Depozit Lipova', 'Depozit extern', 'Order Management', 'Account Management', 'Management General', 'IT ', 'Departament Resurse Umane', 'Departament Financiar'],
  CANVAS: {
    HEIGHT: '150',
    WIDTH: '150'
  }
}
export default CONSTANTS


