import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
const RO = require('../../utils/language/RO.json')

const styles = (theme) => ({

  fullHeight: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#15254b'
  },

  item: {
    flex: '1'
  },

  main: {
    backgroundImage: `url(/assets/intro-main-background.jpg)`,
    backgroundSize: 'cover',
    position: 'relative',
    backgroundPosition: 'center'
  },
  gradient: {
    backgroundImage: 'linear-gradient(rgba(21,37,75, 0.6) 20%, #23201F 97%, #23201F 99%)',
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  details: {
    position: 'absolute',
    height: 200,
    bottom: '0px',
    textAlign: 'center',
    width: '100%',
    '& span:nth-of-type(1)': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundColor: '#A31723',
      borderRadius: '50%',
      margin: '20px',
      marginBottom: '10px',
      marginLeft: 'calc(50% - 10px)',
    },
    '& span.title': {
      color: '#ffffff',
      fontSize: '30px',
      display: 'block',
      textAlign: 'center',
      width: '100%',
      marginBottom: '10px',
    },
    '& span.details': {
      color: '#ffffff'
    }
  },
  logo: {
    position: 'absolute',
    backgroundImage: 'url(/assets/logo.svg)',
    width: '350px',
    height: '50px',
    zIndex: 100,
    marginLeft: 'calc(50% - 150px)',
    marginTop: '50px',
    backgroundSize: 'cover'
  }
})

class IntroView extends React.Component {

  goTo = (path) => {
    this.props.history.push(path)
  }

  render() {

    const { classes } = this.props
    return (
      <div className={classes.fullHeight}>
        <div className={classes.logo}></div>
        <div onClick={() => this.goTo('/tablet')} className={`${classes.item} ${classes.main}`}>
          <div className={classes.gradient}>
            <div className={classes.details}>
              <span></span>
              <span className='title'>Vizita/Guest</span>
              <span className='details'>Welcome to OECHLER</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(IntroView)))
