import React, { useEffect } from 'react'
import Login from './components/login/Login'
import UserView from './components/tablet/UserView'
import ResetPass from './components/resetPass/ResetPass'
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom'
import EntriesList from './components/entriesList/EntriesList'
import theme from '../src/utils/Theme'
import { ThemeProvider } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import axios from './utils/Axios'
import Users from './components/users/Users'
import { useHistory } from 'react-router'
import IntroView from './components/intro/IntroView'
import InfoView from './components/info/InfoView'
import MessageView from './components/common/MessageView'

function Root(props) {

  const history = useHistory()

  const [isLogged, setIsLogged] = React.useState(false)

  useEffect(() => {
    if (props.user) {
      axios.get('/isLogged').then((resp) => {
        setIsLogged(true)
      })
        .catch(() => {
          if (window.location.href !== 'https://oechsler.wesolvvo.com/' && window.location.href !== 'https://oechsler.wesolvvo.com') {
            // window.location.href = '/';
            console.log("You are not logged in")
          }
        })
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        {props.user.role === 'user' ? (
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/entries" component={EntriesList} />
            <Route exact path="/tablet" component={UserView} />
            <Route exact path="/reset/:TOKEN/:ID" component={ResetPass} />
            <Redirect to="/entries" />
          </Switch>
        ) : props.user.role === 'super' ? (
          <Switch>
            <Route exact path="/entries" component={EntriesList} />
            <Route exact path="/" component={Login} />
            <Route exact path="/reset/:TOKEN/:ID" component={ResetPass} />
            <Route exact path="/users" component={Users} />
            <Redirect to="/tracking" />
          </Switch>
        ) : (
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/tablet" component={UserView} />
            <Route exact path="/info" component={InfoView} />
            <Route exact path="/intro" component={IntroView} />
            <Route exact path="/message" component={MessageView} />
            <Route exact path="/reset/:TOKEN/:ID" component={ResetPass} />
            <Redirect to="/" />
          </Switch>
        )}
      </BrowserRouter>
    </ThemeProvider>
  )
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

export default connect(mapStateToProps)(Root)
