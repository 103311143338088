import React from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Modal from './SignatureModal'
import CanvasDraw from 'react-canvas-draw'
import validator from 'validator'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Notifications from '../../utils/Notifications'
import { withStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { FormControlLabel, Checkbox } from '@material-ui/core'
import ConfirmDialog from '../common/ConfirmDialog'
import Select from '@material-ui/core/Select'
import CONSTANTS from '../../utils/Constants'
import { createEntry } from '../../redux/actions/tablet'
import { getCompaniesNoAuth } from '../../redux/actions/companies'
import TabletHeader from '../common/TabletHeader'
const RO = require('../../utils/language/RO.json')

const styles = (theme) => ({

  nextButton: {
    backgroundColor: '#23201F',
    '&:hover': {
      backgroundColor: '#D0D0D0',
    },
    height: '60px',
    marginRight: '0px',
    marginLeft: 'auto',
    marginTop: '20px',
    width: '200px',
    float: 'right',
    textAlign: 'center',
    fontSize: '20px',
    color: '#ffffff',
    lineHeight: '60px',
  },

  flexContainer: {
    display: 'flex',
  },

  email: {
    gridArea: 'email',
  },
  phone: {
    gridArea: 'phone',
  },
  sign: {
    gridArea: 'sign',
    marginTop: '1em',
    marginLeft: '3.5em',
  },
  buttonsContainer: {
    position: 'absolute',
    bottom: '1em',
    left: '2.5%',
    right: '2.5%',
  },
  buttons: {
    backgroundColor: '#23201F',
    '&:hover': {
      backgroundColor: '#D0D0D0',
    },
    display: 'flex',
    justifyContent: 'space-between',
  },
  tabletForm: {
    position: 'relative',
    left: '10%',
    top: '140px',
    width: '80%',
  },
  identityFields: {
    width: '10%',
    marginRight: '2%',
  },
  fullHeight: {
    backgroundColor: '#4B4647',
    position: 'relative',
    overflow: 'auto',
    height: '100vh',
    marginBottom: '100px'
  },
  menuItem: {
    justifyContent: 'space-evenly',
  },
  text: {
    color: '#e0e0e0 !important',
    borderColor: '#e0e0e0 !important',
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: '#e0e0e0'
    },
    '& .MuiFormLabel-root': {
      color: '#e0e0e0 !important'
    },
    height: '40px',

    // '& .MuiInput-underline': {
    //   borderBottom: '1px solid #e0e0e0 !important'
    // }
  },
  textError: {
    color: '#ff0000 !important',
    borderColor: '#ff0000 !important',
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: '#ff0000'
    },
    '& .MuiFormLabel-root': {
      color: '#ff0000 !important'
    },
    height: '40px',

    // '& .MuiInput-underline': {
    //   borderBottom: '1px solid #e0e0e0 !important'
    // }
  },
  logo: {
    position: 'absolute',
    backgroundImage: 'url(/assets/logo.svg)',
    width: '350px',
    height: '50px',
    zIndex: 100,
    marginLeft: 'calc(50% - 150px)',
    marginTop: '70px',
    backgroundSize: 'cover'
  },
  pdf: {
    width: '100%',
    height: '750px'
  }
})

class UserView extends React.Component {
  state = {
    name: '',
    surname: '',
    email: '',
    phone: '',
    show: false,
    series: '',
    number: '',
    observations: '',
    terms: false,
    fields: {
      errorName: false,
      errorSurname: false,
      errorEmail: false,
      errorPhone: false,
      errorSignature: false,
      errorSeries: false,
      errorNumber: false,
      errorObservations: false,
      errorTerms: false
    },
    companies: [],
    openTerms: false
  }

  componentDidMount() {
    this.props.getCompanies().then((resp) => {
      this.setState({ companies: resp })
    })
  }

  showDrawing = () => {
    this.setState({ show: true })
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleChange = (event) => {
    this.setState({ company: event.target.value })
  }

  handleEntries = () => {
    let isOk = true

    let auxFields = {
      errorName: false,
      errorSurname: false,
      errorEmail: false,
      errorCompany: false,
      errorPhone: false,
      errorSignature: false,
      errorSeries: false,
      errorNumber: false,
      errorDuraion: false,
      errorTerms: false
    }

    Object.entries(this.state.fields).forEach(([key, value]) => {
      switch (key) {
        case 'errorSurname':
          if (!this.state.surname || this.state.surname.trim() === '') {
            isOk = false
            auxFields.errorSurname = true
          }
          break
        case 'errorName':
          if (!validator.isAlpha(this.state.name)) {
            isOk = false
            auxFields.errorName = true
          }
          break
        case 'errorEmail':
          // if (!validator.isEmail(this.state.email)) {
          //   isOk = false
          //   auxFields.errorEmail = true
          // }
          break
        case 'errorPhone':
          // if (!validator.isMobilePhone(this.state.phone)) {
          //   isOk = false
          //   auxFields.errorPhone = true
          // }
          break
        case 'errorSignature':
          // if (this.state.show === false) {
          //   isOk = false
          //   auxFields.errorSignature = true
          // }
          break
        case 'errorSeries':
          // if (
          //   !validator.isAlpha(this.state.series) ||
          //   !(this.state.series.length > 0 && this.state.series.length < 3)
          // ) {
          //   isOk = false
          //   auxFields.errorSeries = true
          // }
          break
        case 'errorNumber':
          // if (
          //   !validator.isNumeric(this.state.number) ||
          //   this.state.number.length !== 6
          // ) {
          //   isOk = false
          //   auxFields.errorNumber = true
          // }
          break
        case 'errorDuration':
          if (validator.isEmpty(this.state.duration)) {
            isOk = false
            auxFields.errorDuration = true
          }
          break
        case 'errorTerms':
          if (this.state.terms === false) {
            isOk = false
            auxFields.errorTerms = true
          }
          break
      }
    })
    this.setState({ fields: auxFields })

    if (isOk) {
      let entry = {
        name: this.state.name,
        surname: this.state.surname,
        email: this.state.email,
        phone: this.state.phone,
        signature: localStorage.getItem('savedDrawing'),
        series: this.state.series,
        number: this.state.number,
        department: this.state.department,
        observations: this.state.observations,
        date: new Date(),
      }

      this.setState({
        name: '',
        surname: '',
        email: '',
        phone: '',
        series: '',
        number: '',
        department: '',
        observations: '',
        show: false,
        terms: false
      })
      this.props.createEntry(entry).then((response) => {

        // Notifications.success(RO.notifications.ENTRY_REGISTRATION);
        this.props.history.push(`/message?roText=${entry.surname}, iti multumim pentru vizita ta la Oechsler! Adreseaza-te receptiei pentru indrumare! &enText=${entry.surname}, thank you for visiting Oechsler! Please ask reception for guidance!`)
        // this.props.history.push('/intro')
      })
    } else {
      Notifications.error(RO.notifications.ADMIN_FAIL_REGISTRATION)
    }
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.fullHeight}>
        <TabletHeader title={""} backTo={"/intro"} />

        <ConfirmDialog
          type={"text"}
          open={this.state.openTerms}
          handleClose={() => this.setState({ openTerms: false })}
        >
          <div>
            <b>Date cu caracter personal</b><br />
            Pentru exercitarea drepturilor dumneavoastră vă rugăm să contactați direct responsabilul cu protecția datelor din cadrul Oechsler la adresa dataprotection@oechsler.rosau să depuneți o solicitare scrisă la departamentul de resurse umane în atenția departamentului/persoanei cu atribuții în domeniul protecției datelor cu caracter personal.<br />
            Sunt de acord cu prelucrarea datelor mele personale potrivit prezentei și semnez prezenta spre luare la cunoștință și asumare a politicilor și regulamentelor interne privitoare la protecția datelor personale, ce sunt afișate la intrarea în sediul Oechsler.< br />
            Semnez spre luare la cunoștință și asumare a instrucțiunilor de securitate și protecție în muncă aplicabile în cadrul Oechsler, cu privire la care am fost informat la intrarea în perimetrul Societății. <br />
          </div>
          <br />
          <object data='./assets/flyer.pdf' type="application/pdf" className={classes.pdf}></object>
        </ConfirmDialog >
        <div className={classes.logo}></div>

        <div className={classes.tabletForm}>

          <TextField
            error={this.state.fields.errorSurname}
            inputProps={{ className: classes.text }}
            InputLabelProps={{ className: classes.text }}
            margin="normal"
            required
            fullWidth
            id="surname"
            label={RO.entries.surname}
            name="surname"
            autoComplete="surname"
            onChange={this.onChange}
            value={this.state.surname}
          />
          <TextField
            error={this.state.fields.errorName}
            inputProps={{ className: classes.text }}
            InputLabelProps={{ className: classes.text }}
            margin="normal"
            required
            fullWidth
            id="name"
            label={RO.entries.name}
            name="name"
            autoComplete="name"
            onChange={this.onChange}
            value={this.state.name}
          />
          <div className={classes.flexContainer}>
            <TextField
              className={classes.identityFields}
              inputProps={{ className: classes.text }}
              InputLabelProps={{ className: classes.text }}
              error={this.state.fields.errorSeries}
              margin="normal"
              fullWidth
              id="name"
              label={RO.entries.series}
              name="series"
              autoComplete="name"
              onChange={this.onChange}
              value={this.state.series}
            />
            <TextField
              error={this.state.fields.errorNumber}
              inputProps={{ className: classes.text }}
              InputLabelProps={{ className: classes.text }}
              margin="normal"
              fullWidth
              id="name"
              label={RO.entries.number}
              name="number"
              autoComplete="name"
              onChange={this.onChange}
              value={this.state.number}
            />
          </div>

          <FormControl
            fullWidth
            required
            margin="normal"
            className={classes.text}
            error={this.state.fields.errorDepartment}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              {RO.entries.department}
            </InputLabel>
            <Select
              className={classes.text}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={this.state.department}
              onChange={this.onChange}
              name="department"
            >
              {CONSTANTS.DEPARTMENT.map((time, index) => {
                return <MenuItem value={time}>{time}</MenuItem>
              })}
            </Select>
          </FormControl>

          <div className={this.state.show ? classes.gridContainer : null}>
            <TextField
              className={`${classes.email}`}
              inputProps={{ className: classes.text }}
              InputLabelProps={{ className: classes.text }}
              error={this.state.fields.errorEmail}
              margin="normal"
              fullWidth
              id="email"
              label={RO.entries.email}
              name="email"
              autoComplete="email"
              onChange={this.onChange}
              value={this.state.email}
            />
            <TextField
              className={classes.phone}
              inputProps={{ className: classes.text }}
              InputLabelProps={{ className: classes.text }}
              error={this.state.fields.errorPhone}
              fullWidth
              margin="normal"
              id="phone"
              label={RO.entries.phone}
              name="phone"
              autoComplete="phone"
              onChange={this.onChange}
              value={this.state.phone}
            />
          </div>

          <TextField
            margin="normal"
            inputProps={{ className: classes.text }}
            InputLabelProps={{ className: classes.text }}
            fullWidth
            id="name"
            className={classes.text}
            label={RO.entries.observations}
            name="observations"
            autoComplete="name"
            onChange={this.onChange}
            value={this.state.observations}
            style={{ marginBottom: '40px' }}
          />

          <FormControlLabel className={this.state.fields.errorTerms ? classes.textError : classes.text} control={<Checkbox
            margin="normal"
            id="name"
            className={classes.text}
            name="terms"
            autoComplete="name"

            onChange={this.onChange}
          />} label={<div> Sunt de acord cu <span onClick={() => this.setState({ openTerms: true })} className={classes.terms}>termenii si conditiile</span></div>} />

          <Modal
            showDrawing={this.showDrawing}
            error={this.state.fields.errorSignature}
          />
          <Button
            className={classes.nextButton}
            onClick={() => {
              this.handleEntries()
            }}
          >
            {RO.next}
          </Button>

        </div>
        <div className={classes.buttonsContainer}>
          <div className={classes.buttons}>

          </div>
        </div>
      </div >
    )
  }
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanies: () => dispatch(getCompaniesNoAuth()),
    createEntry: (entry) => dispatch(createEntry(entry)),
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UserView)))
