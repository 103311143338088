import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    overrides: {
        MuiTableCell: {
            head: {
                fontSize: 20,
                fontWeight: 'bold',
                backgroundColor: 'white',
                color: 'black',
            },
            body: {
                fontSize: 14,
            },
        },

        MuiButton: {
            root: {
                marginTop: "1em",
                marginRight: "1em",
                backgroundColor: '#1f1c1b',
                color: "white",
                '&:hover': {
                    backgroundColor: '#1f1c1b',
                }
            },

        },

    }
});

export default theme